import React, { useState, useEffect } from 'react';

const GymTracker = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = '$[Ex2MVcV2#pUFt[3E0bpKj}qC:3qV';
  const [workouts, setWorkouts] = useState({});
  const apiUrl = 'https://msala.dev/api/workouts';
  const [text, setText] = useState('');
  const [animationState, setAnimationState] = useState('typing');
  const fullName = 'matteo sala';

   // Updated dayOrder to handle English days
   const dayOrder = {
    'monday': 0,
    'tuesday': 1,
    'wednesday': 2,
    'thursday': 3,
    'friday': 4,
    'saturday': 5,
    'sunday': 6
  };

  // Italian day names for display (if needed)
  const dayTranslations = {
    'monday': 'Lunedì',
    'tuesday': 'Martedì',
    'wednesday': 'Mercoledì',
    'thursday': 'Giovedì',
    'friday': 'Venerdì',
    'saturday': 'Sabato',
    'sunday': 'Domenica'
  };

  useEffect(() => {
    let isActive = true;

    const typeText = async () => {
      setText('');
      setAnimationState('typing');

      for (let i = 0; i <= fullName.length; i++) {
        if (!isActive) return;
        await new Promise(resolve => setTimeout(resolve, 100));
        setText(fullName.slice(0, i));
      }

      if (!isActive) return;
      await new Promise(resolve => setTimeout(resolve, 400));
      setAnimationState('outlined');

      if (!isActive) return;
      await new Promise(resolve => setTimeout(resolve, 600));
      setAnimationState('initials');
    };

    typeText();
    return () => {
      isActive = false;
    };
  }, []);

  const renderName = () => {
    if (animationState === 'initials') {
      return (
        <h1 className="text-4xl md:text-6xl font-semibold text-black dark:text-white font-mono">
          m-s
        </h1>
      );
    }

    return (
      <h1
        style={{
          WebkitTextStroke: animationState === 'outlined' ? '1px currentColor' : 'initial',
          transition: 'all 0.4s ease-in-out',
        }}
        className={`text-4xl md:text-6xl font-semibold hover:text-gray-700 dark:hover:text-gray-300 transition-all duration-300 font-mono
          ${animationState === 'outlined' ? 'text-transparent' : 'text-black dark:text-white'}`}
      >
        {text}
      </h1>
    );
  };

  const fetchWorkoutsFromServer = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setWorkouts(data);
    } catch (error) {
      console.error('Errore nel caricamento dei dati:', error);
    }
  };

  const saveWorkoutsToServer = async () => {
    try {
      await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(workouts),
      });
    } catch (error) {
      console.error('Errore nel salvataggio dei dati:', error);
    }
  };

  useEffect(() => {
    fetchWorkoutsFromServer();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      saveWorkoutsToServer();
    }
  }, [workouts, isAuthenticated]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Password non corretta');
    }
  };

  const handleUpdateExercise = (day, updatedExercise) => {
    setWorkouts((prevWorkouts) => {
      const newWorkouts = { ...prevWorkouts };
      newWorkouts[day].exercises = newWorkouts[day].exercises.map((exercise) =>
        exercise.id === updatedExercise.id ? updatedExercise : exercise
      );
      return newWorkouts;
    });
  };

  const handleUpdateTitle = (day, newTitle) => {
    setWorkouts((prevWorkouts) => ({
      ...prevWorkouts,
      [day]: {
        ...prevWorkouts[day],
        title: newTitle,
      },
    }));
  };

    const WorkoutTable = ({ day, workoutData }) => {
      const [localExercises, setLocalExercises] = useState(workoutData?.exercises || []);
      const [localTitle, setLocalTitle] = useState(workoutData?.title || '');
  
      useEffect(() => {
        setLocalExercises(workoutData?.exercises || []);
        setLocalTitle(workoutData?.title || '');
      }, [workoutData]);
  
      const handleLocalChange = (index, key, value) => {
        setLocalExercises((prev) => {
          const updated = [...prev];
          updated[index] = { ...updated[index], [key]: value };
          return updated;
        });
      };
  
      const handleBlur = (index) => {
        const updatedExercise = localExercises[index];
        handleUpdateExercise(day, updatedExercise);
      };
  
      const handleTitleBlur = () => {
        handleUpdateTitle(day, localTitle);
      };
  
      // If there are no exercises, render a simplified view
      if (!localExercises.length) {
        return (
          <div className="w-full max-w-2xl mb-6 p-4 border-black/10 dark:border-white/10 rounded-lg">
            <input
              type="text"
              value={localTitle}
              onChange={(e) => setLocalTitle(e.target.value)}
              onBlur={handleTitleBlur}
              disabled={!isAuthenticated}
              className="text-xl md:text-2xl font-mono w-full bg-transparent text-black/80 dark:text-white/80 disabled:opacity-50"
            />
          </div>
        );
      }
  
      return (
        <div className="w-full max-w-2xl mb-6 p-4 border-black/10 dark:border-white/10 rounded-lg">
          <input
            type="text"
            value={localTitle}
            onChange={(e) => setLocalTitle(e.target.value)}
            onBlur={handleTitleBlur}
            disabled={!isAuthenticated}
            className="text-xl md:text-2xl font-mono mb-4 w-full bg-transparent text-black/80 dark:text-white/80 disabled:opacity-50"
          />
          <div className="space-y-3">
            {localExercises.map((exercise, index) => (
              <div 
                key={exercise.id} 
                className="flex flex-col sm:flex-row sm:items-center gap-2 p-3 bg-black/5 dark:bg-white/5 rounded"
              >
                <div className="flex-grow">
                  <textarea
                    rows="1"
                    value={exercise.exercise}
                    onChange={(e) => handleLocalChange(index, 'exercise', e.target.value)}
                    onBlur={() => handleBlur(index)}
                    disabled={!isAuthenticated}
                    className="w-full bg-transparent font-mono text-sm sm:text-base text-black/70 dark:text-white/70 disabled:opacity-50 resize-none overflow-hidden"
                    style={{ minHeight: '24px' }}
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = e.target.scrollHeight + 'px';
                    }}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-xs font-mono text-black/50 dark:text-white/50">kg</span>
                  <input
                    type="text"
                    value={exercise.weight}
                    onChange={(e) => handleLocalChange(index, 'weight', e.target.value)}
                    onBlur={() => handleBlur(index)}
                    disabled={!isAuthenticated}
                    className="w-20 text-right bg-transparent font-mono text-sm sm:text-base text-black/70 dark:text-white/70 disabled:opacity-50"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    };
  
    if (!isAuthenticated) {
      return (
        <div className="min-h-screen w-full flex flex-col items-center justify-center p-4 sm:p-8 bg-white dark:bg-black transition-colors duration-500">
          <div className="w-full max-w-md">
            <div className="h-[72px] flex items-center justify-center mb-8">
              <a href='/'>{renderName()}</a>
            </div>
            <form onSubmit={handleLogin} className="flex flex-col items-center gap-4">
              <input
                type="password"
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-transparent border border-black/10 dark:border-white/10 rounded px-4 py-2 font-mono text-black/70 dark:text-white/70 focus:outline-none focus:border-black/30 dark:focus:border-white/30"
              />
              <button
                type="submit"
                className="font-mono text-black/50 dark:text-white/50 hover:text-black dark:hover:text-white transition-colors duration-300"
              >
                sblocca
              </button>
            </form>
          </div>
        </div>
      );
    }
  
    const sortedWorkouts = Object.entries(workouts)
      .sort(([dayA], [dayB]) => {
        const orderA = dayOrder[dayA.toLowerCase()] ?? Number.MAX_VALUE;
        const orderB = dayOrder[dayB.toLowerCase()] ?? Number.MAX_VALUE;
        return orderA - orderB;
      });
  
    return (
      <div className="min-h-screen w-full flex flex-col items-center p-4 sm:p-8 bg-white dark:bg-black transition-colors duration-500">
        <div className="w-full max-w-2xl mb-8">
          <div className="flex items-center justify-between">
            <div className="h-[72px] flex items-center">
              <a href='/'>{renderName()}</a>
            </div>
            <button
              onClick={() => setIsAuthenticated(false)}
              className="font-mono text-black/50 dark:text-white/50 hover:text-black dark:hover:text-white transition-colors duration-300"
            >
              blocca
            </button>
          </div>
        </div>
  
        <div className="w-full max-w-2xl">
          {sortedWorkouts.map(([day, workoutData]) => (
            <WorkoutTable key={day} day={day} workoutData={workoutData} />
          ))}
        </div>
      </div>
    );
  };
  
  export default GymTracker;
