import React, { useEffect, useState } from 'react';

const LandingPage = () => {
  const [text1, setText1] = useState('');
  const [text2, setText2] = useState('');
  const [animationState, setAnimationState] = useState('typing');
  const firstName = 'matteo';
  const lastName = 'sala';
  
  useEffect(() => {
    const typeText = async () => {
      // Prima fase: digitazione del nome
      for (let i = 0; i <= firstName.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setText1(firstName.slice(0, i));
      }
      
      // Breve pausa tra nome e cognome
      await new Promise(resolve => setTimeout(resolve, 200));
      
      // Seconda fase: digitazione del cognome
      for (let i = 0; i <= lastName.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 100));
        setText2(lastName.slice(0, i));
      }
      
      // Pausa prima dell'outline
      await new Promise(resolve => setTimeout(resolve, 400));
      setAnimationState('outlined');
      
      // Pausa con l'outline
      await new Promise(resolve => setTimeout(resolve, 600));
      setAnimationState('initials');
    };
    
    typeText();
  }, []);

  const renderContent = () => {
    if (animationState === 'initials') {
      return (
        <h1 className="text-6xl font-semibold text-black dark:text-white font-mono">
          m-s
        </h1>
      );
    }

    return (
      <div className="flex flex-col items-center">
        <h1 
          style={{
            WebkitTextStroke: animationState === 'outlined' ? '1px currentColor' : 'initial',
            transition: 'all 0.4s ease-in-out',
          }}
          className={`text-6xl font-semibold hover:text-gray-700 dark:hover:text-gray-300 transition-all duration-300 font-mono
            ${animationState === 'outlined' ? 'text-transparent' : 'text-black dark:text-white'}`}
        >
          {text1}
        </h1>
        <h1 
          style={{
            WebkitTextStroke: animationState === 'outlined' ? '1px currentColor' : 'initial',
            transition: 'all 0.4s ease-in-out',
          }}
          className={`text-6xl font-semibold hover:text-gray-700 dark:hover:text-gray-300 transition-all duration-300 font-mono
            ${animationState === 'outlined' ? 'text-transparent' : 'text-black dark:text-white'}`}
        >
          {text2}
        </h1>
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center bg-white dark:bg-black transition-colors duration-500">
      <div className="flex flex-col items-center">
        <div className="h-[144px] flex items-center justify-center">
        <a href='/'>{renderContent()}</a>
        </div>
        <div className="flex gap-4 mt-8">
          <a
            href="https://github.com/matteosal-a"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black/50 dark:text-white/50 hover:text-black dark:hover:text-white transition-colors duration-300 text-lg flex items-center gap-2 font-mono"
          >
            <svg
              className="w-6 h-6 opacity-50 hover:opacity-100 transition-opacity duration-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1792 1792"
              fill="currentColor"
            >
              <path d="M522 1352q-8 9-20-3-13-11-4-19 8-9 20 3 12 11 4 19zm-42-61q9 12 0 19-8 6-17-7t0-18q9-7 17 6zm-61-60q-5 7-13 2-10-5-7-12 3-5 13-2 10 5 7 12zm31 34q-6 7-16-3-9-11-2-16 6-6 16 3 9 11 2 16zm129 112q-4 12-19 6-17-4-13-15t19-7q16 5 13 16zm63 5q0 11-16 11-17 2-17-11 0-11 16-11 17-2 17 11zm58-10q2 10-14 14t-18-8 14-15q16-2 18 9zm964-956v960q0 119-84.5 203.5t-203.5 84.5h-224q-16 0-24.5-1t-19.5-5-16-14.5-5-27.5v-239q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-121-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-86-13.5q-44 113-7 204-79 85-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-40 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 103t.5 68q0 22-11 33.5t-22 13-33 1.5h-224q-119 0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0 203.5 84.5t84.5 203.5z"/>
            </svg>
            github
          </a>
          <a
	  href="/lg"
  className="text-black/50 dark:text-white/50 hover:text-black dark:hover:text-white transition-colors duration-300 text-lg flex items-center gap-2 font-mono"
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="w-5 h-5">
    <path d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4"/>
    <path d="M4 12H14M14 12L11 9M14 12L11 15"/>
  </svg>
  login
</a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
